.header-container{
    direction: rtl;
    background-color: rgb(58, 3, 147);
    display: flex;
    flex-direction: row;
    height: 5%;
    box-shadow: 0px 2px rgba(0, 28, 142, 0.598);
    padding: 10px;
    padding-inline: 20px;
    align-items: center;
    z-index: 10;
}

.header-logo-container{
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width:350px;
    cursor: pointer;
}

.header-logo{
    height: 100%;
    background-color: white;

}

.header-logo-name{
    color: white;
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.admin-page-navigator{
 position: absolute;
 inset-inline-end: 20px;
 cursor: pointer;
}