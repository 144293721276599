.results-page-container{
    display: flex;
    direction: rtl;
    flex-direction: row;
    justify-content: space-evenly;
    height: 95%;
}

.search-side{
    flex:1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.result-search-title{
    text-align: center;
    width: 100%;
    height: 2vh;
    margin-top:1rem;
    margin-bottom:1rem;
    font-size: 1.1em;
    font-weight: 600;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.results-container{
    flex:1.5;
    border: 1px solid black;
    overflow: scroll;
    scrollbar-width: none;
}

.results{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 10px;
    padding: 16px;
}

.loading-results{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}
.result-amount{
    text-align: center;
    width: 100%;
    height: 2vh;
    margin-top:1rem;
    font-size: 1.1em;
    font-weight: 600;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.results-map{
    flex:1;
    background-color: greenyellow;
}

.results-map .map-container{
    height: 100%;
    width: 100%;
}

.hidden{
    display: none;
}