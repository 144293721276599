.category-picker-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.picker-card-region{
    width: 30vw;
    aspect-ratio: 3.5;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.picker-card-feature{
    width: 20vw;
    aspect-ratio: 2.5;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.picker-card-feature:hover, .picker-card-region:hover{
    transform: rotateX(15deg);
}

.picker-card-icon{
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blanchedalmond;
    border-radius: 50px;
    transform: scale(0.7);
}

.feature-icon{
    background-color: rgb(173, 173, 173);
}
.picker-card-title{
    font-size: 1.5em;
    font-weight: 600;
}

.picker-container{
    width: 100%;
    height: fit-content;
    padding: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.picker-title{
    font-size: 1.3em;
    font-weight: 700;
}

.sub-title{
    font-size: 2em;
    font-weight: 700;
}

.mapped-locations-container{
    direction: rtl;
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}
.map-icon{
    width: 100%;
    height: 50%;
}

.star-icon{
    height: 50%;
}