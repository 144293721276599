.all-places-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    position: relative;
}

.all-places-card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px ;
    width: 100%;
    height: 50%;
}

.all-places-title{
    font-size: 2em;
    font-weight: 700;
    margin-block: 50px;
}

.all-places-card-container .slick-track {
  display: flex !important;
  flex-direction: row;
  height: 100%;
}
.all-places-card-container .slick-slide {
    padding: 10px;
  }
  
.all-places-card-container .slick-dots {
    bottom:-30px;
}
  
.custom-arrow {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    transform: scale(1.5);
  }
  
.left-arrow .slick-prev{
    left: 100px;
    bottom: 100px;
  }
  
.right-arrow .slick-next{
    right: 40px;
    }
  
.place-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.place-card-wrapper .place-card-info-line{
  padding-top: 50px;
}

