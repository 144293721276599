.admin-users-container{
    width: 90%;
    direction: rtl;
    margin-inline: 5%;
}

.admin-users-header{
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr;
    width: 55%;
    margin-block: 10px;
}

.admin-users-header-item{
    font-size: 1.5rem;
    font-weight: 800;
    font-family:Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.admin-user-container{
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr;
    width: 55%;
    margin-block: 10px;
}

.new-user-form {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 20px;
    height: 50px;
    align-items: center;
}

.new-user-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
}

.new-user-button {
    padding: 10px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.new-user-button:hover {
    background-color: #4cae4c;
}
