/* DeleteModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-actions .delete-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .modal-actions button:hover {
    opacity: 0.9;
  }
  
.delete-modal-opener{
    border: 1px solid black;
    background-color: white;
    border-radius: 10px;
    width:80px;
    height: 30px;
    color: #e74c3c;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 2px 2px black;
}

.delete-modal-opener:hover{
    background-color: #e74c3c;
    color: white;
}