/* Spinner.css */
@keyframes spinner {
    to { transform: rotate(360deg); }
  }
  
.spinner {
    display: inline-block;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #000;
    animation: spinner 0.6s linear infinite;
  }
  