.filter-display-container{
    width:90%;
    margin-inline: 5%;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px
}